import cn from 'clsx';
import * as React from 'react';
import { CSSProperties, forwardRef, memo } from 'react';
import equals from 'react-fast-compare';

import { BorderProps, FontSizeVals, IMarginProps } from '../../enhancers';
import { useThemeIsDark } from '../../hooks';
import { useImage } from '../../hooks/use-image';
import { randomColorFromString, readableColor } from '../../utils';
import { Box } from '../Box';
import { Flex } from '../Flex';
import { Icon, IIconProps } from '../Icon';
import { Image } from '../Image';

type AvatarSize = 'sm' | 'md' | 'lg' | 'xl' | '2xl';

export const sizes: Record<AvatarSize, [number, FontSizeVals, FontSizeVals]> = {
  '2xl': [64, '3xl', '3xl'],
  xl: [48, 'xl', '2xl'],
  lg: [32, 'lg', 'xl'],
  md: [24, 'base', 'base'],
  sm: [20, 'sm', 'xs'],
};

export type AvatarProps = IMarginProps &
  Pick<BorderProps, 'border' | 'borderColor'> & {
    size?: AvatarSize;
    className?: string;

    /**
     * Optional background color.
     *
     * - If `fg` is set then `bg` defaults to a lighter shade of `fg`
     * - If `fg` is not set and `name` is set, then `bg` defaults to a random color that is generated based on the `name` value.
     */
    bg?: CSSProperties['backgroundColor'];

    /**
     * Optional foreground color.
     */
    fg?: CSSProperties['color'];

    /**
     * The name of the entity in the avatar.
     *
     * - if `src` is also set, the name will be used in the `alt` attribute of the image.
     * - If `src` is not set, the name will be used to create the initials
     */
    name?: string;

    /**
     * @deprecated Use `name` instead.
     */
    letter?: string;

    /**
     * The image url of the `Avatar`
     */
    src?: string;

    /**
     * The icon to use in the `Avatar`.
     */
    icon?: IIconProps['icon'];

    /**
     * @deprecated Omit `name`, `src`, and `icon` to get equivalent "blank/spacer" avatar.
     */
    blank?: true;

    /**
     * Optionally force an inverted icon.
     *
     * `isInverted` defaults to true when an avatar is rendered in dark mode context.
     */
    isInverted?: boolean;

    /**
     * Do not set a title or alt attribute. Useful if handling this another way, for example with a wrapping tooltip.
     */
    noTitleAlt?: boolean;
  };

export const Avatar = memo(
  forwardRef<HTMLDivElement, AvatarProps>(function Avatar(props, ref) {
    const isDark = useThemeIsDark();

    let {
      className,
      size = 'md',
      bg,
      fg,
      icon,
      name,
      src,
      letter,
      blank,
      isInverted = isDark,
      noTitleAlt,
      ...rest
    } = props;

    const isBlank = (!icon && !name && !src && !letter) || blank;

    const { backgroundColor, textColor, backgroundOpacity } = useColor({ bg, fg, name, isInverted });

    /**
     * use the image hook to only show the image when it has loaded
     */
    const status = useImage({ src });
    const hasLoaded = status === 'loaded';

    /**
     * Fallback avatar applies under 2 conditions:
     *
     * - If `src` was passed and the image has not loaded or failed to load
     * - If `src` wasn't passed
     *
     * For this case the fallback will either be the name based initials, custom icon, or default icon.
     */
    const showFallback = !src || !hasLoaded;

    let innerElem;
    if (showFallback && !isBlank) {
      if (icon) {
        innerElem = <Icon icon={icon} fixedWidth />;
      } else if (name || letter) {
        innerElem = <Box>{letter ? letter : initials(name, size !== 'lg')}</Box>;
      } else {
        innerElem = <Icon icon="user" fixedWidth />;
      }
    } else if (src && hasLoaded) {
      innerElem = (
        <Image src={src} alt={noTitleAlt ? undefined : name} objectFit="cover" w="full" h="full" rounded="full" />
      );
    }

    return (
      <Flex
        ref={ref}
        className={cn('sl-avatar', { 'sl-avatar--with-bg': !isBlank }, className)}
        align="center"
        justify="center"
        pos="relative"
        title={noTitleAlt ? undefined : name}
        fontSize={icon ? sizes[size]?.[2] : sizes[size]?.[1]}
        rounded="full"
        lineHeight="none"
        fontWeight="semibold"
        userSelect="none"
        overflowX="hidden"
        overflowY="hidden"
        flexShrink={0}
        style={{
          width: sizes[size]?.[0],
          height: sizes[size]?.[0],

          // @ts-expect-error perfectly valid, csstype just doesn't allow for arbitrary css variables
          '--avatar-bg-color': backgroundColor,
          '--avatar-bg-opacity': backgroundOpacity,
        }}
        {...rest}
      >
        <Box pos="relative" style={{ color: textColor }}>
          {innerElem}
        </Box>
      </Flex>
    );
  }),
  equals,
);

const useColor = ({
  bg,
  fg,
  name,
  isInverted,
}: Pick<AvatarProps, 'bg' | 'fg' | 'name' | 'isInverted'>): {
  backgroundColor: CSSProperties['backgroundColor'];
  backgroundOpacity: CSSProperties['opacity'];
  textColor: CSSProperties['color'];
} => {
  const defaultBG = isInverted ? 'var(--color-text-muted)' : 'var(--color-canvas-100)';
  const defaultTextColor = isInverted ? 'var(--color-canvas-pure)' : 'var(--color-text-muted)';

  let backgroundColor = bg;
  let textColor = fg;
  let backgroundOpacity = 1;

  if (fg) {
    backgroundColor = fg;
    backgroundOpacity = isInverted ? 0.25 : 0.2;
  } else if (!bg) {
    backgroundColor = name ? randomColorFromString(name) : defaultBG;
  }

  if (isInverted && !fg && backgroundColor !== defaultBG) {
    textColor = backgroundColor;
    backgroundColor = 'rgba(255, 255, 255, 0.95)';
  }

  if (!textColor) {
    // we almost always want white for the avatar use case, only pick black if the white on backgroundColor contrast is really bad
    textColor = backgroundColor === defaultBG ? defaultTextColor : readableColor(backgroundColor, 12);
  }

  return { backgroundColor, textColor, backgroundOpacity };
};

function initials(name: string, single?: boolean) {
  const [first, second] = name.split(' ');
  return first && second && !single ? `${first.charAt(0)}${second.charAt(0)}` : first.charAt(0);
}
